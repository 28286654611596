<template>
  <div class="wrap">
    <div
      class="label"
      :class="{ 'label-on': active }"
      @click="active = !active"
    >
      <icons class="label-icon" iconName="language" size="24" />
      <texts class="label-text" text="Select display text" />
      <icons class="label-toggle" iconName="toggle" size="24" />
    </div>
    <transition name="toggle-content">
      <div v-if="active" class="list">
        <div class="item" @click="_selectLanguage('ja')">
          <icon-languages class="item-icon" iconName="japanese" />
          <texts class="item-text" text="日本語" />
        </div>
        <div class="item" @click="_selectLanguage('en')">
          <icon-languages class="item-icon" iconName="english" />
          <texts class="item-text" text="English" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import texts from '@/components/atoms/text'
import icons from '@/components/atoms/icon'
import iconLanguages from '@/components/atoms/icon-languages'

export default {
  components: {
    texts,
    icons,
    iconLanguages
  },
  computed: {
    ...mapGetters('setting', ['language'])
  },
  data() {
    return {
      /** 言語選択を開いているかどうか */
      active: false
    }
  },
  methods: {
    ...mapActions('settings', ['changeLanguage']),
    _selectLanguage: function (val) {
      this.changeLanguage({ value: val, i18n: this.$i18n })
      this.active = false
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $space-sub $space-base $space-sub $space-sub;
  border-radius: adjustVW(8);
  cursor: pointer;
  user-select: none;
  transition: box-shadow $transition-base;
  &-on {
    background: $background;
    box-shadow: $border-radius-emphasis;
  }
  &-icon {
    margin: 0 $space-base 0 0;
  }
  &-text {
    margin: 0 $space-base 0 0;
  }
  &-toggle {
    transition: transform $transition-base;
    .label-on & {
      transform: rotate(180deg);
    }
  }
}
.list {
  width: fit-content;
  padding: $space-small;
  margin: $space-small 0 0 auto;
  background: $background;
  border-radius: adjustVW(8);
  box-shadow: $box-shadow-hover;
}
.item {
  display: flex;
  align-items: center;
  padding: 0 $space-base $space-small;
  margin: 0 0 $space-small;
  border-bottom: $border-main;
  cursor: pointer;
  &:last-of-type {
    padding: 0 $space-base;
    margin: 0;
    border: none;
  }
  &-icon {
    margin: 0 $space-sub 0 0;
    box-shadow: $box-shadow-main;
  }
}

.toggle-content {
  &-enter {
    opacity: 0;
    transform: translateY(-$space-small);
    &-active {
      transition: opacity $transition-base,
        transform ease-in-out $transition-base;
    }
  }
  &-leave {
    &-to {
      opacity: 0;
    }
    &-active {
      transition: opacity $transition-base;
    }
  }
}
</style>
