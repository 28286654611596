<template>
  <div class="form-title">
    <texts
      class="form-title-main"
      size="big"
      :text="$t('loginPage.resetPassword.title')"
    />
    <texts
      class="form-title-notice"
      :text="$t('loginPage.resetPassword.notice')"
    />
  </div>
</template>

<script>
import texts from '@/components/atoms/text.vue'

export default {
  components: {
    texts
  }
}
</script>

<style lang="scss" scoped>
.form-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: $space-small;

  &-main {
    margin-bottom: $space-base;
  }

  &-notice {
    font-size: $text-base;
    white-space: pre-line;
  }
}
</style>
