<template>
  <div class="forgot-password">
    <button v-on:click="switchContent">
      <texts :text="$t('loginPage.forgotPassword')" :size="'link'" />
    </button>
  </div>
</template>

<script>
import texts from '@/components/atoms/text.vue'

export default {
  components: {
    texts
  },
  data() {
    return {
      content: 'login'
    }
  },
  methods: {
    switchContent() {
      this.content = 'reset-password'
      this.$emit('switch-content', {
        content: this.content
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.forgot-password {
  width: 100%;
  margin: $space-base 0;
}
</style>
