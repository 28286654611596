<template>
  <input-form-box
    v-bind="coupon"
    :title="this.$i18n.t('signup.form.coupon')"
    @change="changeVal"
  />
</template>

<script>
import inputFormBox from '@/components/molecules/signup/input-form-box.vue'

export default {
  components: {
    inputFormBox
  },
  data() {
    return {
      coupon: {
        isError: false,
        popupView: false,
        value: ''
      }
    }
  },
  methods: {
    changeVal(val) {
      this.coupon.value = val
      this.$emit('change', { value: val, isOk: true })
    }
  }
}
</script>
