<template>
  <div class="login-form">
    <form @submit.prevent @keypress.prevent.enter.exact="submitLogin">
      <div class="login-form-input">
        <div class="login-form-item">
          <input-box-validation
            v-model="id"
            autocomplete="email"
            autocorrect="off"
            autocapitalize="off"
            class="login-form-inputbox"
            :title="$t('loginPage.login.inputID')"
          />
        </div>
        <div class="login-form-item">
          <input-box-validation
            v-model="pass"
            autocomplete="current-password"
            autocorrect="off"
            autocapitalize="off"
            class="login-form-inputbox"
            :title="$t('loginPage.login.inputPassword')"
            :isPass="true"
          />
        </div>
      </div>
      <login-error-message
        v-show="loginError"
        :reason="reason"
        class="login-form-item"
      />
      <button-large
        class="login-form-submit-button"
        button-type="submit"
        type="emphasis"
        :text="$t('loginPage.login.submitButton')"
        :isDisabled="disableLogin"
        @click="submitLogin"
      />
    </form>
  </div>
</template>

<script>
import buttonLarge from '@/components/atoms/button-large.vue'
import inputBoxValidation from '@/components/molecules/input-box-validation.vue'
import loginErrorMessage from '@/components/molecules/login/login-error-message.vue'

export default {
  components: {
    buttonLarge,
    inputBoxValidation,
    loginErrorMessage
  },
  data() {
    return { id: '', pass: '', disableLogin: true }
  },
  props: {
    loginError: {
      type: Boolean,
      default: false
    },
    reason: String
  },
  computed: {
    _fulfillForm() {
      return this.id && this.pass
    }
  },
  watch: {
    _fulfillForm(val) {
      if (val) {
        this.disableLogin = false
      } else {
        this.disableLogin = true
      }
    }
  },
  methods: {
    submitLogin() {
      this.$emit('submit-login', {
        id: this.id,
        pass: this.pass
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: $space-medium;
  background: $background;

  &-item {
    width: 100%;
    margin-bottom: $space-medium;
    font-size: $text-page-title;
    font-weight: bold;
    @include text-crop;
  }

  &-input {
    margin-bottom: $space-base;
  }

  &-inputbox {
    width: 100%;
  }

  &-submit-button {
    width: 100%;
  }
}
</style>
