<template>
  <div>
    <button v-on:click="goSignupPage">
      <texts size="link" :text="$t('loginPage.login.signupLink')" />
    </button>
  </div>
</template>

<script>
import texts from '@/components/atoms/text.vue'

export default {
  components: {
    texts
  },
  methods: {
    goSignupPage: function () {
      this.$router.push('signup')
    }
  }
}
</script>
