<template>
  <div class="verify-wrap">
    <transition-toggle-contents>
      <loading v-if="loading" class="verify-loading" />
      <card-base v-else-if="error" class="verify-card">
        <texts
          class="verify-title"
          :text="$t('verify.errorTitle')"
          size="title"
        />
        <texts class="verify-text" :text="$t('verify.errorBody')" />
        <button-main
          v-if="!loading"
          :text="$t('verify.backToSettings')"
          type="emphasis"
          @click="goAccountInfo"
        />
      </card-base>
    </transition-toggle-contents>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { sendVerifyToken } from '@/lib/auth.js'
import setMountedTimer from '@/mixin/set-mounted-timer'
import loading from '@/components/atoms/loading'
import texts from '@/components/atoms/text.vue'
import cardBase from '@/components/atoms/card-base.vue'
import buttonMain from '@/components/atoms/button-main.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'

export default {
  name: 'PageVerify',
  components: {
    loading,
    texts,
    cardBase,
    buttonMain,
    transitionToggleContents
  },
  mixins: [setMountedTimer],
  data: function () {
    return {
      loading: false,
      error: false
    }
  },
  mounted() {
    this.sendToken()
  },
  computed: {
    ...mapGetters('auth', ['loggedIn']),
    token() {
      return this.$route.params.token
    }
  },
  methods: {
    ...mapActions('auth', ['fetchAccountInfo', 'fetchLoginInfo']),
    async sendToken() {
      this.loading = true
      try {
        // email address verification
        let verificationSucceeded = false
        try {
          const res = await sendVerifyToken({ token: this.token })
          verificationSucceeded = res.result === 'success'
        } catch {
          verificationSucceeded = false
        }
        if (!verificationSucceeded) this.error = true
        // reload information
        let reloadSucceeded = false
        try {
          await this.fetchLoginInfo()
          if (this.loggedIn) {
            await this.fetchAccountInfo()
          }
          reloadSucceeded = true
        } catch {
          this.loading = false
          reloadSucceeded = false
        }
        if (!reloadSucceeded) this.error = true
      } finally {
        this.loading = false
      }

      if (!this.error) {
        this.$notify({
          group: 'alerts',
          type: 'primary',
          title: this.$t('verify.success'),
          duration: 5000,
          data: {
            dismissible: false
          }
        })
        this.$router.push({ name: 'myAccountInfo' })
      }
    },
    goAccountInfo() {
      this.$router.push({
        name: 'myAccountInfo'
      })
    }
  }
}
</script>
<style scoped lang="scss">
.verify {
  &-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
  }
  &-card {
    padding: $space-medium;
  }
  &-title {
    margin-bottom: $space-small;
  }
  &-text {
    margin-bottom: $space-medium;
    white-space: pre-line;
  }
}
</style>
