<template>
  <div class="reset-password-accepted">
    <text-box
      class="reset-password-accepted-message"
      :isAccept="true"
      :isFolded="true"
      :text="$t('loginPage.resetPassword.accepted')"
    />
  </div>
</template>

<script>
import textBox from '@/components/atoms/text-box.vue'

export default {
  components: {
    textBox
  }
}
</script>
