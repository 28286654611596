<template>
  <div class="signup-form">
    <form @submit.prevent>
      <input
        type="text"
        name="username"
        autocomplete="username"
        class="stop-autocomplete-dummy"
      >
      <input
        type="password"
        autocomplete="new-password"
        class="stop-autocomplete-dummy"
      >
      <input
        type="text"
        name="field2_c"
        value="無料ユーザ登録"
        class="stop-autocomplete-dummy"
      >
      <transition-toggle-slot-contents getHeightInMount>
        <signup-first
          v-if="!showSecondStep && !waitCheckAccount"
          v-bind="backValue"
          :disableSignup="disableSignupFirst"
          :waitCheckAccount="waitCheckAccount"
          @change-nickname="changeNickname"
          @change-account="changeAccountId"
          @change-email="changeEmail"
          @change-pass="changePassword"
        />
        <signup-second
          v-else-if="!waitCheckAccount"
          :disableSignup="disableSignupSecond"
          @change-company="changeCompany"
          @change-department="changeDepartment"
          @change-jobtitle="changeJobtitle"
          @change-phone="changePhone"
          @change-coupon="changeCoupon"
          @check-TOS="checkTOS"
        />
      </transition-toggle-slot-contents>
      <button-large
        class="login-form-submit-button"
        button-type="submit"
        type="emphasis"
        :text="dispSubmitText"
        :isDisabled="checkDisabled"
        @click="showSecondStep ? submitSignup() : goNext()"
      >
        <loading-icon
          v-if="waitCheckAccount"
          class="signup-form-submit-loading"
          color="gray"
        />
      </button-large>
      <transition name="error">
        <div v-if="errorMessage && errorMessage.length > 0">
          <text-box
            v-for="(error, index) in errorMessage"
            :key="index"
            class="signup-form-error-message"
            isError
            isFolded
            :text="error"
          />
        </div>
      </transition>
    </form>
  </div>
</template>

<script>
import buttonLarge from '@/components/atoms/button-large.vue'
import textBox from '@/components/atoms/text-box.vue'
import loadingIcon from '@/components/atoms/loading-icon'
import transitionToggleSlotContents from '@/components/molecules/transition-toggle-slot-contents'
import signupFirst from './signup-first'
import signupSecond from './signup-second'

export default {
  components: {
    textBox,
    buttonLarge,
    loadingIcon,
    transitionToggleSlotContents,
    signupFirst,
    signupSecond
  },
  data() {
    return {
      accountId: {
        value: '',
        isOk: false
      },
      coupon: {
        value: '',
        isOk: true
      },
      email: {
        value: '',
        isOk: false
      },
      nickname: {
        value: '',
        isOk: false
      },
      password: {
        value: '',
        isOk: false
      },
      customerInfo: {
        company: null,
        department: null,
        phone: null,
        jobtitle: null
      },
      agreeTermsOfService: false,
      disableSignupFirst: true,
      disableSignupSecond: true,
      backValue: null
    }
  },
  props: {
    signupError: {
      type: Boolean,
      default: false
    },
    isSignuping: Boolean,
    signupErrorMessage: {
      type: [Array],
      default: null
    },
    showSecondStep: Boolean,
    waitCheckAccount: Boolean
  },
  computed: {
    _fulfillForm() {
      return (
        this.accountId.isOk &&
        this.email.isOk &&
        this.nickname.isOk &&
        this.password.isOk &&
        this.agreeTermsOfService &&
        this.coupon.isOk &&
        this._checkcustomerInfo
      )
    },
    _firstfillForm() {
      return (
        this.accountId.isOk &&
        this.email.isOk &&
        this.nickname.isOk &&
        this.password.isOk
      )
    },
    _checkcustomerInfo() {
      const checkTarget = Object.entries(this.customerInfo)
      return !checkTarget.some((item) => {
        if (item[0] === 'jobtitle') return false
        return !item[1]
      })
    },
    errorMessage() {
      let message

      if (!this.signupErrorMessage) {
        message = []
      } else {
        message = this.signupErrorMessage.map((msg) =>
          this.$t('signup.error.' + msg)
        )
      }

      return message
    },
    checkDisabled() {
      const first = this.disableSignupFirst && !this.showSecondStep
      const second = this.disableSignupSecond && this.showSecondStep
      return first || second || this.waitCheckAccount
    },
    dispSubmitText() {
      if (this.waitCheckAccount) {
        return ''
      } else if (this.showSecondStep) {
        return this.$t('signup.form.submitButton')
      } else {
        return this.$t('signup.form.submitFirst')
      }
    }
  },
  watch: {
    _fulfillForm(val) {
      if (val) {
        this.disableSignupSecond = false
      } else {
        this.disableSignupSecond = true
      }
    },
    _firstfillForm(val) {
      if (val) {
        this.disableSignupFirst = false
      } else {
        this.disableSignupFirst = true
      }
    },
    isSignuping: function (val) {
      this.disableSignup = !val
    },
    signupErrorMessage: function (val) {
      if (val === 'CODE_NOT_EXISTS') {
        this.coupon.isOk = false
      }
      this.backValue = null
      if (!this.showSecondStep) {
        this.backValue = {
          backNickname: this.nickname.value,
          backAccountId: this.accountId.value,
          backEmail: this.email.value,
          backPassword: this.password.value
        }
      }
    },
    showSecondStep: function (bool) {
      this.backValue = null
      if (!bool) {
        this.backValue = {
          backNickname: this.nickname.value,
          backAccountId: this.accountId.value,
          backEmail: this.email.value,
          backPassword: this.password.value
        }
      }
    }
  },
  methods: {
    changeAccountId(id) {
      this.accountId.value = id.value
      this.accountId.isOk = id.isOk
    },
    changeCoupon(coupon) {
      this.coupon.value = coupon.value
      this.coupon.isOk = coupon.isOk
    },
    changeEmail(email) {
      this.email.value = email.value
      this.email.isOk = email.isOk
    },
    changeNickname(nickname) {
      this.nickname.value = nickname.value
      this.nickname.isOk = nickname.isOk
    },
    changePassword(password) {
      this.password.value = password.value
      this.password.isOk = password.isOk
    },
    checkTOS(value) {
      this.agreeTermsOfService = value
    },
    changeCompany(value) {
      this.customerInfo.company = value
    },
    changeDepartment(value) {
      this.customerInfo.department = value
    },
    changePhone(value) {
      this.customerInfo.phone = value
    },
    changeJobtitle: function (value) {
      this.customerInfo.jobtitle = value
    },
    submitSignup() {
      const newAccount = {
        accountId: this.accountId.value,
        coupon: this.coupon.value,
        email: this.email.value,
        emailConfirm: this.email.value,
        name: this.nickname.value,
        passWord: this.password.value,
        passWordConfirm: this.password.value,
        company: this.customerInfo.company,
        department: this.customerInfo.department,
        phone: this.customerInfo.phone,
        jobtitle: this.customerInfo.jobtitle
      }

      this.$emit('submit-signup', newAccount)
    },
    goNext() {
      this.$emit('go-next', {
        accountId: this.accountId.value,
        email: this.email.value,
        emailConfirm: this.email.value,
        name: this.nickname.value,
        passWord: this.password.value,
        passWordConfirm: this.password.value
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.signup-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: $background;

  &-submit-button {
    width: 100%;
  }
  &-submit-loading {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  &-error-message {
    margin-top: $space-large;
  }
}

.stop-autocomplete-dummy {
  display: none;
}

.error {
  &-enter-active {
    transition: opacity $transition-base;
  }
  &-enter {
    opacity: 0;
    will-change: opacity;
  }
}
</style>
