<template>
  <div class="signup-second-wrap">
    <input-affiliation
      @change-company="$emit('change-company', $event)"
      @change-department="$emit('change-department', $event)"
    />
    <input-job-info
      @change-jobtitle="$emit('change-jobtitle', $event)"
      @change-phone="$emit('change-phone', $event)"
    />
    <input-coupon-code isMin @change="$emit('change-coupon', $event)" />
    <terms-link @input="$emit('check-TOS', $event)" />
  </div>
</template>

<script>
import inputAffiliation from '@/components/molecules/signup/input-affiliation'
import inputJobInfo from '@/components/molecules/signup/input-job-info'
import inputCouponCode from '../../molecules/signup/input-coupon-code.vue'
import termsLink from '@/components/molecules/signup/terms-link.vue'
export default {
  components: {
    inputAffiliation,
    inputJobInfo,
    inputCouponCode,
    termsLink
  }
}
</script>

<style lang="scss" scoped>
.signup-second-wrap {
  margin-bottom: adjustVW(28);
}
</style>
