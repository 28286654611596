<template>
  <signup
    v-bind="data"
    @submit-signup="submitSignup"
    @go-next="checkAccountData"
    @back-form="backForm"
  />
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
import setMountedTimer from '@/mixin/set-mounted-timer'

import signup from '@/components/templates/signup.vue'

export default {
  components: {
    signup
  },
  mixins: [setMountedTimer],
  data() {
    return {
      data: {
        body: {
          isSignuping: false,
          otp: false,
          signupError: false,
          signupErrorMessage: null,
          showSecondStep: false,
          waitCheckAccount: false
        },
        waitLogin: false
      }
    }
  },
  methods: {
    ...mapActions('auth', ['fetchAccountInfo']),

    checkAccountData: async function (accountData) {
      this.data.body.waitCheckAccount = true
      this.data.body.signupErrorMessage = null
      this.data.body.signupError = false

      try {
        await axios.post('/api/validate_signup', accountData)

        this.$gtmDataLayer.sendEvent('singupStatus', 'first')
      } catch (err) {
        this.log_info(err)
        this.data.body.signupError = true

        const statusCode = err.request.status

        window.setTimeout(() => {
          if (statusCode === 0) {
            this.data.body.signupErrorMessage = ['CONNECTION_REFUSED']
          } else if (statusCode >= 500) {
            this.data.body.signupErrorMessage = ['SERVER_ERROR']
          } else {
            const data = err.response && err.response.data
            const errorDetail = data.detail

            const keys = Object.keys(data.detail)
            const messageList = keys
              .map((key) => errorDetail[key].message)
              .filter((msg) => msg?.length > 0)

            this.data.body.signupErrorMessage = messageList
          }
        }, 400)
      } finally {
        window.setTimeout(() => {
          this.data.body.waitCheckAccount = false
          if (!this.data.body.signupError) {
            this.data.body.showSecondStep = true
          }
        }, 400)
      }
    },

    submitSignup: async function ({
      accountId,
      coupon,
      email,
      emailConfirm,
      name,
      passWord,
      passWordConfirm,
      company,
      department,
      phone,
      jobtitle
    }) {
      this.data.body.signupError = false
      this.data.body.isSignuping = true
      this.data.body.signupErrorMessage = null

      this.data.waitLogin = true

      try {
        await axios.post('/api/signup', {
          accountId,
          coupon,
          email,
          emailConfirm,
          name,
          passWord,
          passWordConfirm,
          company,
          department,
          phone,
          jobtitle
        })
      } catch (error) {
        this.data.body.signupError = true

        if (error.request.status === 0) {
          this.data.body.signupErrorMessage = ['CONNECTION_REFUSED']
        } else if (error.request.status >= 500) {
          this.data.body.signupErrorMessage = ['SERVER_ERROR']
        } else {
          const data = error.response && error.response.data
          const errorDetail = data.detail

          const keys = Object.keys(data.detail)
          const messageList = keys
            .map((key) => errorDetail[key].message)
            .filter((msg) => msg?.length > 0)

          this.data.body.signupErrorMessage = messageList
        }
        this.data.waitLogin = false
        this.data.body.isSignuping = false
      }

      if (this.data.body.signupError === false) {
        this.$gtmDataLayer.sendEvent('singupStatus', 'comp')
        this.$router.push('login')
      }
    },
    backForm() {
      this.data.body.waitCheckAccount = true
      window.setTimeout(() => {
        this.data.body.showSecondStep = false
        this.data.body.waitCheckAccount = false
      }, 300)
    }
  }
}
</script>
