<template>
  <div class="signup-agree-terms-wrapper">
    <label>
      <input
        class="input-check-box"
        type="checkbox"
        :checked="isChecked"
        @change="updateValue($event)"
      >
      <div class="signup-agree-terms-check">
        <icons
          class="signup-agree-terms-check-icon"
          iconName="check"
          size="min"
          color="emphasis"
        />
      </div>
    </label>
    <div class="signup-agree-terms-text">
      <texts size="small">
        <span v-if="!$te('signup.form.agreeJa')">{{
          $t('signup.form.agree')
        }}</span>
        <a :href="termsLink" target="_blank" rel="noreferrer">
          {{ $t('signup.form.termsOfService') }}
        </a>
        <span>{{ $t('signup.form.and') }}</span>
        <a :href="privacyPolicyLink" target="_blank">
          {{ $t('signup.form.privacyPolicy') }}
        </a>
        <span v-if="$te('signup.form.agreeJa')">{{
          $t('signup.form.agreeJa')
        }}</span>
      </texts>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text.vue'
import icons from '@/components/atoms/icon.vue'

export default {
  components: {
    icons,
    texts
  },
  data() {
    return {
      isChecked: this.checked
    }
  },
  props: {
    checked: Boolean
  },
  watch: {
    checked(newVal) {
      this.isChecked = newVal
    }
  },
  methods: {
    updateValue(e) {
      this.isChecked = e.target.checked
      this.$emit('input', this.isChecked)
    }
  },
  computed: {
    termsLink() {
      return this.$urls.terms[this.$i18n.locale]
    },
    privacyPolicyLink() {
      return this.$urls.privacyPolicy[this.$i18n.locale]
    }
  }
}
</script>

<style lang="scss" scoped>
.signup-agree-terms {
  &-wrapper {
    position: relative;
    padding: 0 $space-medium 0 $space-large;
    cursor: pointer;
  }

  &-check {
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: adjustVW(24);
    height: adjustVW(24);
    border: $border-title-gray;
    background: $background;
    border-radius: adjustVW(4);
    transform: translateY(-50%);

    &-icon {
      opacity: 0;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: adjustVW(40);
      height: adjustVW(40);
      transform: translate(-50%, -50%);
    }
  }
}

.input-check-box[type='checkbox']:checked + .signup-agree-terms-check {
  border: $border-emphasis;
  background: $background;
  > .signup-agree-terms-check-icon {
    opacity: 1;
  }
}
</style>
