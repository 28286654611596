<template>
  <div class="c-otp-form-content">
    <div class="c-otp-form-body">
      <one-time-password-form
        :otpError="otpError"
        @submit-otp="submitOTP"
        @switch-content="switchContent"
      />
    </div>
  </div>
</template>

<script>
import OneTimePasswordForm from '@/components/molecules/login/one-time-password-form.vue'

export default {
  components: {
    OneTimePasswordForm
  },
  props: {
    otpError: Boolean
  },
  methods: {
    submitOTP({ token }) {
      this.$emit('submit-otp', { token })
    },
    switchContent({ content }) {
      this.$emit('switch-content', { content })
    }
  }
}
</script>

<style scoped lang="scss">
.c-otp-form-content {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.c-otp-form-body {
  padding: 0 adjustVW(84);
}
</style>
