<template>
  <div class="otp-form-wrapper">
    <div class="otp-form-title-wrapper">
      <texts
        class="otp-form-title-main"
        size="big"
        :text="this.$i18n.t('loginPage.otp.title')"
      />

      <texts
        class="otp-form-title-detail"
        size="small"
        :text="this.$i18n.t('loginPage.otp.detail')"
      />
    </div>

    <div class="otp-form-input-wrapper">
      <div class="otp-form-input-item-name">
        <texts size="small" :text="this.$i18n.t('loginPage.otp.form.title')" />
      </div>
      <input-box
        v-model="otpToken"
        type="string"
        :placeholder="this.$i18n.t('loginPage.otp.form.placeholder')"
        :value.sync="otpToken"
        isGray
      />
    </div>

    <div class="otp-form-error-wrapper">
      <one-time-password-error v-if="otpError" />
    </div>

    <div class="otp-form-submit-wrapper">
      <button-large
        :text="this.$i18n.t('loginPage.otp.submitButton')"
        :isDisabled="this.isDisabled"
        @click="submitOTP"
      />
    </div>

    <div class="otp-form-return-login-wrapper">
      <button @click="returnLogin">
        <texts :text="$t('loginPage.otp.loginLink')" :size="'link'" />
      </button>
    </div>
  </div>
</template>

<script>
import ButtonLarge from '@/components/atoms/button-large.vue'
import InputBox from '@/components/molecules/input-box.vue'
import Texts from '@/components/atoms/text.vue'
import OneTimePasswordError from '@/components/molecules/login/one-time-password-error.vue'

export default {
  components: {
    ButtonLarge,
    InputBox,
    Texts,
    OneTimePasswordError
  },
  props: {
    otpError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      otpToken: '',
      isDisabled: true
    }
  },
  watch: {
    otpToken: function (val) {
      if (val.length === 6) this.isDisabled = false
      else this.isDisabled = true
    }
  },
  methods: {
    returnLogin() {
      this.$emit('switch-content', {
        content: 'login'
      })
    },
    submitOTP() {
      this.$emit('submit-otp', { token: this.otpToken })
    }
  }
}
</script>

<style lang="scss" scoped>
.otp-form-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.otp-form-title-wrapper {
  margin-bottom: $space-small;
}

.otp-form-title {
  &-main {
    margin-bottom: $space-base;
  }
  &-detail {
    white-space: normal;
  }
}

.otp-form-input-wrapper {
  margin-bottom: $space-medium;
}

.otp-form-input-item-name {
  margin-bottom: $space-base;
}

.otp-form-error-wrapper {
  margin-bottom: $space-medium;
}

.otp-form-submit-wrapper {
  width: 100%;
  margin-bottom: $space-small;
}

.otp-form-return-login-wrapper {
  width: adjustVW(316);
}
</style>
