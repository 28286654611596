<template>
  <div class="signup-input-multi">
    <input-nickname
      :backNickname="backNickname"
      @change="$emit('change-nickname', $event)"
    />
    <input-account-id
      :backAccountId="backAccountId"
      @change="$emit('change-account', $event)"
    />
  </div>
</template>

<script>
import inputAccountId from '@/components/molecules/signup/input-account-id.vue'
import inputNickname from '@/components/molecules/signup/input-nickname.vue'

export default {
  components: {
    inputAccountId,
    inputNickname
  },
  props: {
    backNickname: String,
    backAccountId: String
  }
}
</script>

<style lang="scss" scoped>
.signup-input-multi {
  display: flex;
  grid-column-gap: adjustVW(28);
}
</style>
