<template>
  <div>
    <button v-if="!showSecondStep" @click="goLoginPage">
      <texts size="link" :text="$t('signup.loginLink')" />
    </button>
    <button v-else @click="backForm">
      <texts size="link" :text="$t('signup.backlink')" />
    </button>
  </div>
</template>

<script>
import texts from '@/components/atoms/text.vue'

export default {
  components: {
    texts
  },
  props: {
    showSecondStep: Boolean
  },
  methods: {
    goLoginPage: function () {
      this.$router.push('login')
    },
    backForm: function () {
      this.$emit('back-form')
    }
  }
}
</script>
