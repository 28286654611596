<template>
  <div class="c-reset-password-content">
    <div class="c-reset-password-body">
      <form-title />
      <reset-password-form
        :isAccepted="this.isAccepted"
        :isSubmitting="this.isSubmitting"
        :sendErrorMessage="sendErrorMessage"
        @submit="sendMail"
      />
      <login-link @switch-content="switchContent" />
    </div>
  </div>
</template>

<script>
import formTitle from '@/components/molecules/reset-password/form-title.vue'
import loginLink from '@/components/molecules/reset-password/login-link.vue'
import resetPasswordForm from '@/components/molecules/reset-password/reset-password-form.vue'

export default {
  components: {
    formTitle,
    loginLink,
    resetPasswordForm
  },
  props: {
    isAccepted: Boolean,
    isSubmitting: Boolean,
    sendErrorMessage: String,
    resetPasswordError: Boolean
  },
  methods: {
    sendMail({ email }) {
      this.$emit('send-reset-password-mail', { email })
    },
    switchContent({ content }) {
      this.$emit('switch-content', { content })
    }
  }
}
</script>

<style scoped lang="scss">
.c-reset-password-content {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.c-reset-password-body {
  padding: 0 adjustVW(84);
}
</style>
