<template>
  <div class="c-wrap" :class="{ 'c-wrap-wait-login': waitLogin }">
    <transition appear name="login-toggle">
      <div class="c-title-wrap">
        <transition name="image-toggle" mode="out-in">
          <div v-if="!waitLogin" class="c-title-logo" />
          <loading-login v-else />
        </transition>
      </div>
    </transition>
    <transition-page name="transition-parts" mode="out-in">
      <div class="c-body-wrap">
        <header class="c-header-tab">
          <select-language-box />
        </header>
        <transition-toggle-contents>
          <login
            v-if="this.content == 'login'"
            v-bind="loginPageData"
            key="login"
            @submit-login="submitLogin"
            @switch-content="switchContent"
          />
          <one-time-password-form
            v-if="this.content == 'otp'"
            v-bind="otpPageData"
            key="otp"
            @submit-otp="submitOTP"
            @switch-content="switchContent"
          />
          <reset-password
            v-if="this.content == 'reset-password'"
            v-bind="resetPasswordPageData"
            key="reset-password"
            @send-reset-password-mail="sendResetPasswordMail"
            @switch-content="switchContent"
          />
          <set-password
            v-if="this.content == 'set-password'"
            key="set-password"
            @switch-content="switchContent"
          />
        </transition-toggle-contents>
        <div class="link-terms">
          <a
            class="link-terms-item"
            :href="$urls.personalInfomationProtection"
            target="_blank"
            rel="noopener"
          >
            <texts
              class="link-terms-item-inner"
              :text="$t('loginPage.personalInfomationProtection')"
              color="gray"
              size="small"
            />
          </a>
          <a
            class="link-terms-item"
            :href="setPrivacyPolicyLink"
            target="_blank"
            rel="noopener"
          >
            <texts
              class="link-terms-item-inner"
              :text="$t('signup.form.privacyPolicy')"
              color="gray"
              size="small"
            />
          </a>
        </div>
      </div>
    </transition-page>
    <login-bg class="bg" />
  </div>
</template>

<script>
import Login from '@/components/organisms/login/login.vue'
import OneTimePasswordForm from '@/components/organisms/login/one-time-password-form.vue'
import ResetPassword from '@/components/organisms/login/reset-password.vue'
import SetPassword from '@/components/organisms/login/set-password.vue'
import SelectLanguageBox from '@/components/molecules/select-language-box.vue'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import TransitionPage from '@/components/molecules/transition-page.vue'
import LoadingLogin from '@/components/atoms/loading-login.vue'
import LoginBg from '@/assets/images/login-bg.svg'

export default {
  components: {
    Login,
    OneTimePasswordForm,
    ResetPassword,
    SetPassword,
    SelectLanguageBox,
    TransitionToggleContents,
    TransitionPage,
    LoadingLogin,
    LoginBg
  },
  props: {
    waitLogin: Boolean,
    content: String,
    loginPageData: Object,
    otpPageData: Object,
    resetPasswordPageData: Object
  },
  methods: {
    sendResetPasswordMail({ email }) {
      this.$emit('send-reset-password-mail', { email })
    },
    submitSetPassword({ email }) {
      this.$emit('submit-set-password', { email })
    },
    submitLogin: function ({ id, pass }) {
      this.$emit('submit-login', { id, pass })
    },
    submitOTP({ token }) {
      this.$emit('submit-otp', { token })
    },
    switchContent({ content }) {
      this.$emit('switch-content', { content })
    }
  },
  computed: {
    setPrivacyPolicyLink() {
      return this.$urls.privacyPolicy[this.$i18n.locale]
    }
  }
}
</script>

<style lang="scss" scoped>
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
  height: 100vh;
  background: $background-sub;
  &-wait-login {
    .c-title-wrap {
      width: 100%;
    }
    .c-body-wrap {
      transform: translateX(100%);
    }
  }
}

.c-title-wrap {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: adjustVW(1200);
  padding: $space-medium;
  z-index: 1;
  transition: all $transition-base;
}

.c-title-logo {
  width: 100%;
  height: 100%;
  @include logo(320, 160);
  background-position: center;
}

.c-body-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: $space-medium;
  background: $background;
  z-index: 1;
  transition: transform $transition-base;
}

.c-header-tab {
  position: fixed;
  top: $space-medium;
  right: $space-large;
  z-index: $z-header-tab;
}

.link-terms {
  position: absolute;
  right: adjustVW(32);
  bottom: adjustVW(32);
  display: flex;
  grid-column-gap: $space-medium;
  &-item {
    display: block;
    cursor: pointer;
    &-inner {
      text-decoration: underline;
    }
  }
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.5;
  z-index: 0;
}

.login-toggle {
  will-change: opacity, transform;
  &-enter {
    transform: translateX(calc(100vw - #{adjustVW(1200)}));
    &-active {
      transition: opacity $transition-base,
        transform ease-in-out $transition-base;
    }
  }
  &-leave {
    &-to {
      opacity: 0;
    }
    &-active {
      transition: opacity $transition-base;
    }
  }
}

.image-toggle {
  will-change: opacity, transform;
  &-enter {
    opacity: 0;
    &-active {
      transition: opacity $transition-base;
    }
  }
  &-leave {
    &-to {
      opacity: 0;
    }
    &-active {
      transition: opacity $transition-base;
    }
  }
}
</style>
