<template>
  <div class="c-login-content">
    <div class="c-login-body">
      <form-title
        :showSecondStep="showSecondStep"
        @back-form="$emit('back-form')"
      />
      <signup-form
        :isSignuping="isSignuping"
        :signupError="signupError"
        :signupErrorMessage="signupErrorMessage"
        :showSecondStep="showSecondStep"
        :waitCheckAccount="waitCheckAccount"
        @submit-signup="$emit('submit-signup', $event)"
        @go-next="$emit('go-next', $event)"
      />
    </div>
  </div>
</template>

<script>
import formTitle from '@/components/molecules/signup/form-title.vue'
import signupForm from '@/components/organisms/signup/signup-form.vue'

export default {
  components: {
    signupForm,
    formTitle
  },
  props: {
    isSignuping: {
      type: Boolean,
      default: false
    },
    otp: {
      type: Boolean,
      default: false
    },
    signupError: {
      type: Boolean,
      default: false
    },
    signupErrorMessage: {
      type: Array,
      default: null
    },
    showSecondStep: {
      type: Boolean,
      default: false
    },
    waitCheckAccount: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.c-login-content {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.c-login-body {
  padding: 0 adjustVW(64);
}
</style>
