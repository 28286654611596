<template>
  <div class="reset-password-accepted">
    <text-box
      class="reset-password-accepted-message"
      isError
      :isFolded="true"
      :text="sendErrorMessage"
    />
  </div>
</template>

<script>
import textBox from '@/components/atoms/text-box.vue'

export default {
  components: {
    textBox
  },
  props: {
    sendErrorMessage: String
  }
}
</script>
