<template>
  <div class="c-wrap" :class="{ 'c-wrap-wait-login': waitLogin }">
    <transition appear name="signup-toggle">
      <div class="c-title-wrap">
        <transition name="image-toggle" mode="out-in">
          <div v-if="!waitLogin" class="c-title-logo" />
          <loading-login v-else />
        </transition>
      </div>
    </transition>
    <transition-page>
      <div class="c-body-wrap">
        <header class="c-header-tab">
          <select-language-box />
        </header>
        <signup
          v-bind="body"
          @submit-signup="$emit('submit-signup', $event)"
          @go-next="$emit('go-next', $event)"
          @back-form="$emit('back-form', $event)"
        />
      </div>
    </transition-page>
    <loginBg class="bg" />
  </div>
</template>

<script>
import signup from '@/components/organisms/signup/signup.vue'
import selectLanguageBox from '@/components/molecules/select-language-box.vue'
import TransitionPage from '@/components/molecules/transition-page.vue'
import LoadingLogin from '@/components/atoms/loading-login.vue'
import loginBg from '@/assets/images/login-bg.svg'

export default {
  components: {
    signup,
    selectLanguageBox,
    TransitionPage,
    LoadingLogin,
    loginBg
  },
  props: {
    body: Object,
    waitLogin: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.c-wrap {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  min-width: 960px;
  height: 100vh;
  background-image: linear-gradient(
    120deg,
    rgba(224, 195, 252, 0.7) 0%,
    rgba(142, 197, 252, 0.5) 100%
  );
  &-wait-login {
    .c-title-wrap {
      width: 100%;
    }
    .c-body-wrap {
      transform: translateX(-100%);
    }
  }
}

.c-title-wrap {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: adjustVW(1160);
  padding: $space-medium;
  z-index: 1;
  transition: all $transition-base;
}

.c-title-logo {
  width: 100%;
  height: 100%;
  @include logo(320, 160);
  background-position: center;
}

.c-body-wrap {
  position: relative;
  width: 100%;
  padding: $space-medium;
  background: $background;
  z-index: 1;
  transition: transform $transition-base;
}

.c-header-tab {
  position: absolute;
  top: $space-medium;
  right: $space-large;
  z-index: $z-header-tab;
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.5;
  z-index: 0;
}

.signup-toggle {
  will-change: opacity, transform;
  &-enter {
    transform: translateX(calc((100vw - #{adjustVW(1160)}) * -1));
    &-active {
      transition: opacity $transition-base,
        transform ease-in-out $transition-base;
    }
  }
  &-leave {
    &-to {
      opacity: 0;
    }
    &-active {
      transition: opacity $transition-base;
    }
  }
}
.image-toggle {
  will-change: opacity, transform;
  &-enter {
    opacity: 0;
    &-active {
      transition: opacity $transition-base;
    }
  }
  &-leave {
    &-to {
      opacity: 0;
    }
    &-active {
      transition: opacity $transition-base;
    }
  }
}
</style>
