<template>
  <div class="signup-input-multi">
    <input-form-box
      name="company"
      v-bind="company"
      :title="$t('signup.form.company.name')"
      :exampleText="$t('signup.form.company.placeHolder')"
      @change="_changeCompany"
    />
    <input-form-box
      name="job_function"
      v-bind="department"
      :title="$t('signup.form.department.name')"
      :exampleText="$t('signup.form.department.placeHolder')"
      @change="_changeDepartment"
    />
  </div>
</template>

<script>
import inputFormBox from '@/components/molecules/signup/input-form-box.vue'

export default {
  components: {
    inputFormBox
  },
  data() {
    return {
      company: {
        value: ''
      },
      department: {
        value: ''
      }
    }
  },
  methods: {
    _changeCompany(val) {
      this.company.value = val
      this.$emit('change-company', val)
    },
    _changeDepartment(val) {
      this.department.value = val
      this.$emit('change-department', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.signup-input-multi {
  display: flex;
  grid-column-gap: adjustVW(28);
}
</style>
