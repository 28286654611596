<template>
  <div class="form-title">
    <div class="title">
      <texts size="big" :text="$t('signup.form.title')" />
    </div>
    <div class="login-link">
      <login-link
        :showSecondStep="showSecondStep"
        @back-form="$emit('back-form')"
      />
    </div>
  </div>
</template>

<script>
import loginLink from '@/components/molecules/signup/login-link.vue'
import texts from '@/components/atoms/text.vue'

export default {
  components: {
    loginLink,
    texts
  },
  props: {
    showSecondStep: Boolean
  }
}
</script>

<style lang="scss" scoped>
.form-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: adjustVW(40);
}
</style>
