<template>
  <login
    v-bind="data"
    @send-reset-password-mail="sendResetPasswordMail"
    @submit-login="submitLogin"
    @submit-otp="submitOTP"
    @switch-content="switchContent"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import login from '@/components/templates/login.vue'
import { sendPasswordReset } from '@/lib/auth.js'
import setMountedTimer from '@/mixin/set-mounted-timer'

export default {
  components: {
    login
  },
  mixins: [setMountedTimer],
  data() {
    return {
      data: {
        waitLogin: false,
        content: this.$route.params.content ?? 'login',
        loginPageData: {
          loginError: false,
          reason: ''
        },
        otpPageData: {
          otpError: false
        },
        resetPasswordPageData: {
          isAccepted: false,
          isSubmitting: false,
          sendErrorMessage: null,
          resetPasswordError: false
        }
      },
      otp: false
    }
  },
  computed: {
    ...mapGetters('auth', ['loggedIn', 'otpAuthState'])
  },
  watch: {
    otpAuthState(val) {
      if (val) this.switchContent({ content: 'otp' })
    }
  },
  methods: {
    ...mapActions('auth', ['cancelOTP', 'sendOTP', 'signIn']),

    initErrorStatus: function () {
      this.data.loginPageData.loginError = false
      this.data.otpPageData.otpError = false
      this.data.resetPasswordError = false
      this.data.resetPasswordPageData.isAccepted = false
    },
    sendResetPasswordMail: async function ({ email }) {
      let res = null
      this.data.resetPasswordPageData.isAccepted = false
      this.data.resetPasswordPageData.isSubmitting = true
      this.data.resetPasswordPageData.sendErrorMessage = null

      try {
        res = await sendPasswordReset({ email })
        this.data.resetPasswordPageData.isSubmitting = false
        this.data.resetPasswordPageData.isAccepted = res.result === 'success'
      } catch (e) {
        const data = e.response && e.response.data
        this.data.resetPasswordPageData.isSubmitting = false

        if (data && data.reason) {
          this.data.resetPasswordPageData.sendErrorMessage = this.$t(
            'passwordReset.message.incorrect'
          )
          throw new Error(data.reason)
        } else if (e.request.status === 0) {
          this.data.resetPasswordPageData.sendErrorMessage = this.$t(
            'common.error.CONNECTION_REFUSED'
          )
          throw new Error('CONNECTION_REFUSED')
        } else {
          this.data.resetPasswordPageData.sendErrorMessage = this.$t(
            'common.error.SERVER_ERROR'
          )
          throw new Error('SERVER_ERROR')
        }
      }
    },
    submitLogin: async function ({ id, pass }) {
      this.data.loginPageData.loginError = false
      this.data.waitLogin = true

      try {
        await this.signIn({ accountId: id, password: pass })
        if (this.loggedIn && !this.otpAuthState) {
          this.$router
            .push({ path: 'projects' })
            .catch((err) => this.log_info(err))
        } else if (this.otpAuthState) {
          this.data.waitLogin = false
        }
      } catch (e) {
        this.log_info(e)

        this.data.loginPageData.loginError = true
        this.$set(this.data.loginPageData, 'reason', e.message)

        this.data.waitLogin = false
      }
    },
    submitOTP: async function ({ token }) {
      this.data.otpPageData.otpError = false

      try {
        await this.sendOTP({ otpToken: token })
        this.$router
          .push({ path: 'projects' })
          .catch((err) => this.log_info(err))
      } catch (e) {
        this.log_info(e)
        this.data.otpPageData.otpError = true
      }
    },
    switchContent({ content, params = {} }) {
      this.initErrorStatus()
      if (content !== 'otp') this.cancelOTP()
      this.data.content = content
      if (content !== 'login') {
        this.$router.push({
          name: 'loginSubpage',
          params: {
            ...params,
            content: content
          }
        })
      } else {
        this.$router.push({
          name: 'login',
          params: {
            ...params,
            content: content
          }
        })
      }
    }
  }
}
</script>
