<template>
  <div class="wrap">
    <svg
      :is="componentName"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 26"
      :aria-labelledby="iconName"
      role="presentation"
      width="2.0833333vw"
      height="1.3541666vw"
    />
  </div>
</template>

<script>
// dummy
import dummyIcon from '../../assets/icons/common/dummy.svg' // ダミーアイコン

// languages
import japaneseIcon from '../../assets/icons/languages/japanese.svg' // 日本語
import englishIcon from '../../assets/icons/languages/english.svg' // 英語

export default {
  components: {
    dummyIcon,
    japaneseIcon,
    englishIcon
  },
  props: {
    /** 言語選択のアイコン名[ japanese, english ] */
    iconName: {
      type: String,
      default: 'dummy'
    }
  },
  computed: {
    componentName() {
      const name = this.iconName + 'Icon'
      if (name in this.$options.components) {
        return name
      }
      return 'dummyIcon'
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
