<template>
  <div class="login-error">
    <text-box
      class="login-error-message"
      :isError="true"
      :isFolded="true"
      :text="errorMessage"
    />
  </div>
</template>

<script>
import textBox from '@/components/atoms/text-box.vue'

export default {
  components: {
    textBox
  },
  props: {
    reason: String
  },
  computed: {
    errorMessage: function () {
      if (this.reason === 'NOT_ALLOWED_IP') {
        return this.$i18n.t('loginPage.login.error.ipRestriction')
      } else if (this.reason === 'INCORRECT_IP_IN_LIST') {
        return this.$i18n.t('loginPage.login.error.incorrectIpIncluded')
      }

      return this.$i18n.t('loginPage.login.error.incorrectId')
    }
  }
}
</script>

<style lang="scss" scoped>
.login-error {
  width: 100%;
  margin-top: -$space-base;
}
</style>
