<template>
  <div class="c-login-content">
    <div class="c-login-body">
      <form-title />
      <login-form
        :loginError="loginError"
        :reason="reason"
        @submit-login="submitLogin"
      />
      <forgot-password @switch-content="switchContent" />
    </div>
  </div>
</template>

<script>
import ForgotPassword from '@/components/molecules/login/forgot-password.vue'
import FormTitle from '@/components/molecules/login/form-title.vue'
import LoginForm from '@/components/molecules/login/login-form.vue'

export default {
  components: {
    ForgotPassword,
    FormTitle,
    LoginForm
  },
  props: {
    loginError: {
      type: Boolean,
      default: false
    },
    reason: String
  },
  methods: {
    submitLogin: function ({ id, pass }) {
      this.$emit('submit-login', { id, pass })
    },
    switchContent({ content }) {
      this.$emit('switch-content', { content })
    }
  }
}
</script>

<style scoped lang="scss">
.c-login-content {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.c-login-body {
  padding: 0 adjustVW(84);
}
</style>
