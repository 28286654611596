<template>
  <text-box
    class="one-time-password-error-message"
    :isError="true"
    :isFolded="true"
    :text="$t('loginPage.otp.error')"
  />
</template>

<script>
import textBox from '@/components/atoms/text-box.vue'

export default {
  components: {
    textBox
  }
}
</script>

<style lang="scss" scoped>
.login-error {
  width: 100%;
  margin-top: -$space-base;
}
</style>
