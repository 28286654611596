<template>
  <div class="c-reset-password-content">
    <div class="c-reset-password-body">
      <div class="form-title">
        <texts
          class="form-title-main"
          size="big"
          :text="$t('passwordReset.setPassword.title')"
        />
      </div>
      <form @submit.prevent @keypress.prevent.enter.exact="submit">
        <div class="reset-password-item">
          <input-box-validation
            v-model="password"
            isPass
            class="reset-password-inputbox"
            :accept="acceptPassword"
            :error="acceptPassword === false && password.length > 0"
            :popupText="errorPassword"
            :placeholder="this.$i18n.t('signup.example.password')"
            popupView
            autocomplete="email"
            autocorrect="off"
            autocapitalize="off"
            :title="$t('passwordReset.newPassWord')"
          />
        </div>
        <div class="reset-password-item">
          <input-box-validation
            v-model="passwordConfirm"
            isPass
            class="reset-password-inputbox"
            :accept="acceptPasswordConfirm"
            :error="
              acceptPasswordConfirm === false && passwordConfirm.length > 0
            "
            :popupText="errorPasswordConfirm"
            :placeholder="this.$i18n.t('signup.example.password')"
            popupView
            autocomplete="email"
            autocorrect="off"
            autocapitalize="off"
            :title="$t('passwordReset.newPassWordConfirm')"
          />
        </div>
        <error-message
          v-if="showingIncorrectErrorMessage"
          :sendErrorMessage="$t('passwordReset.message.error')"
          class="reset-password-item"
        />
        <button-large
          class="reset-password-submit-button"
          button-type="submit"
          type="emphasis"
          :text="$t('passwordReset.send')"
          :isDisabled="disableSubmit"
          @click="submit"
        />
      </form>
      <login-link @switch-content="switchContent" />
    </div>
  </div>
</template>

<script>
import errorMessage from '@/components/molecules/reset-password/error-message.vue'
import buttonLarge from '@/components/atoms/button-large.vue'
import inputBoxValidation from '@/components/molecules/input-box-validation.vue'
import loginLink from '@/components/molecules/reset-password/login-link.vue'
import { sendNewPassword, validatePassword, errorPassword } from '@/lib/auth.js'

export default {
  components: {
    errorMessage,
    buttonLarge,
    inputBoxValidation,
    loginLink
  },
  data() {
    return {
      password: '',
      passwordConfirm: '',
      processing: false,
      showingIncorrectErrorMessage: false
    }
  },
  props: {},
  computed: {
    acceptPassword() {
      return validatePassword(this.password)
    },
    acceptPasswordConfirm() {
      if (!this.password) return null
      return this.password === this.passwordConfirm
    },
    errorPassword() {
      return errorPassword.bind(this)(this.password)
    },
    errorPasswordConfirm() {
      if (this.acceptPasswordConfirm === false) {
        return this.$t('passwordReset.errors.notMatch')
      }
      return null
    },
    disableSubmit() {
      return (
        !this.acceptPassword || !this.acceptPasswordConfirm || this.processing
      )
    },
    token() {
      return this.$route.params.token
    }
  },
  methods: {
    switchContent() {
      this.$emit('switch-content', {
        content: 'login'
      })
    },
    async submit() {
      this.processing = true
      this.showingIncorrectErrorMessage = false
      try {
        const res = await sendNewPassword({
          token: this.token,
          password: this.password,
          passwordConfirm: this.passwordConfirm
        })
        if (res.result === 'error') {
          throw res
        }
        this.$notify({
          group: 'alerts',
          type: 'primary',
          title: this.$t('passwordReset.message.passwordResetSuccess'),
          duration: 5000,
          data: {
            dismissible: false
          }
        })
        this.$emit('switch-content', {
          content: 'login'
        })
      } catch (e) {
        this.log_info(e)
        this.showingIncorrectErrorMessage = true
      } finally {
        this.processing = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.c-reset-password-content {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.c-reset-password-body {
  padding: 0 adjustVW(84);
}

.form-title {
  margin-bottom: $space-medium;
}

.reset-password {
  &-item {
    margin-bottom: $space-medium;
  }
  &-submit-button {
    margin-bottom: $space-medium;
  }
}
</style>
