<template>
  <div class="signup-input-multi">
    <input-form-box
      name="jobtitle"
      v-bind="jobTitle"
      :title="$t('signup.form.jobTitle.name')"
      :exampleText="$t('signup.form.jobTitle.placeHolder')"
      @change="_changeJobTitle"
    />
    <input-form-box
      name="phone"
      v-bind="phone"
      :title="$t('signup.form.phone.name')"
      :errorText="$t('signup.form.phone.error')"
      :exampleText="$t('signup.form.phone.placeholder')"
      @change="_changePhone"
    />
  </div>
</template>

<script>
import inputFormBox from '@/components/molecules/signup/input-form-box.vue'

export default {
  components: {
    inputFormBox
  },
  data() {
    return {
      jobTitle: {
        value: ''
      },
      phone: {
        isError: false,
        value: ''
      }
    }
  },
  methods: {
    _changeJobTitle(val) {
      this.jobTitle.value = val

      this.$emit('change-jobtitle', val)
    },
    _changePhone(val) {
      this.phone.value = val

      const target = val.split('-').join('')
      const check = /^0[0-9]{9,10}$/

      if (check.test(target)) {
        this.$emit('change-phone', val)
        this.phone.isError = false
      } else {
        this.$emit('change-phone', false)
        this.phone.isError = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.signup-input-multi {
  display: flex;
  grid-column-gap: adjustVW(28);
}
</style>
