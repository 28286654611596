<template>
  <div>
    <input-account-and-nickname
      :backNickname="backNickname"
      :backAccountId="backAccountId"
      @change-nickname="$emit('change-nickname', $event)"
      @change-account="$emit('change-account', $event)"
    />
    <input-email
      :backEmail="backEmail"
      @change="$emit('change-email', $event)"
    />
    <input-password
      :backPassword="backPassword"
      isMin
      @change="$emit('change-pass', $event)"
    />
  </div>
</template>

<script>
import inputAccountAndNickname from '@/components/molecules/signup/input-account-and-nickname'
import inputEmail from '@/components/molecules/signup/input-email.vue'
import inputPassword from '@/components/molecules/signup/input-password.vue'
export default {
  components: {
    inputAccountAndNickname,
    inputEmail,
    inputPassword
  },
  props: {
    backNickname: String,
    backAccountId: String,
    backEmail: String,
    backPassword: String
  }
}
</script>
