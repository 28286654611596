<template>
  <div class="signup-input-multi">
    <input-form-box
      name="email"
      v-bind="email"
      emailSuggest
      :nameValidationSuggests="emailSuggestArray"
      :title="this.$i18n.t('signup.form.email')"
      :errorText="this.$i18n.t('signup.error.email')"
      :exampleText="this.$i18n.t('signup.example.email')"
      @change="_changeEmail"
    />
    <input-form-box
      name="email"
      v-bind="emailConfirm"
      :title="this.$i18n.t('signup.form.emailConfirm')"
      :errorText="this.$i18n.t('signup.error.emailConfirm')"
      @change="_changeEmailConfirm"
    />
  </div>
</template>

<script>
import inputFormBox from '@/components/molecules/signup/input-form-box.vue'
import axios from 'axios'
import { debounce } from 'lodash'

export default {
  components: {
    inputFormBox
  },
  data() {
    return {
      email: {
        isError: false,
        isOk: false,
        popupView: false,
        value: ''
      },
      emailConfirm: {
        isError: false,
        isOk: false,
        popupView: false,
        value: ''
      },
      confirmOk: false,

      emailSuggestArray: [],
      emailAvailable: null
    }
  },
  props: {
    backEmail: String
  },
  computed: {
    _validateEmailConfirm: function () {
      return { email: this.email.value, emailConfirm: this.emailConfirm.value }
    }
  },
  watch: {
    _validateEmailConfirm: function ({ email, emailConfirm }) {
      this.emailConfirm.isOk =
        email === emailConfirm ? emailConfirm !== '' : false
      this.emailConfirm.isError = !this.emailConfirm.isOk
        ? emailConfirm !== ''
        : false
      this.emailConfirm.popupView = !this.emailConfirm.isOk
        ? emailConfirm !== ''
        : false

      this.$emit('change', {
        value: this.emailConfirm.value,
        isOk: this.email.isOk && this.emailConfirm.isOk
      })
    },
    'email.value': function (val) {
      this.email.isError = false
      this.email.isOk = false

      if (val !== '') {
        this.email.isOk = this._validateEmail(val)
        this.email.isError = !this.email.isOk
        this.email.popupView = !this.email.isOk
      }
    }
  },
  methods: {
    _changeEmail(val) {
      this.email.value = val
      this.checkEmail()
    },
    _changeEmailConfirm(val) {
      this.emailConfirm.value = val
    },
    _validateEmail(email) {
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/.test(
          email
        )
      )
        return false
      return true
    },
    checkEmail: debounce(async function () {
      if (this.email.value !== '') {
        const filterValue = this.email.value.replaceAll(/\s/g, '')
        const resSuggest = await axios.post('/api/domain_suggest', {
          inputdata: {
            email: filterValue
          },
          typename: 'email'
        })
        this.emailSuggestArray = resSuggest.data.results

        const res = await axios.post(
          '/api/email_available',
          { email: this.email.value },
          { validateStatus: () => true }
        )
        this.emailAvailable = res.data.status !== 'error'
      } else {
        this.emailAvailable = null
      }
    }, 400)
  },
  mounted() {
    if (this.backEmail) {
      this.email.value = this.backEmail
      this.$nextTick(() => {
        this.emailConfirm.value = this.backEmail
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.signup-input-multi {
  display: flex;
  grid-column-gap: adjustVW(28);
}
</style>
