<template>
  <input-form-box
    name="accountId"
    :errorText="errorText"
    :explanationText="this.$i18n.t('signup.description.accountID')"
    :exampleText="this.$i18n.t('signup.example.accountID')"
    :title="this.$i18n.t('signup.form.accountID')"
    v-bind="accountId"
    @change="changeVal"
  />
</template>

<script>
import axios from 'axios'
import { debounce } from 'lodash'

import inputFormBox from '@/components/molecules/signup/input-form-box.vue'

export default {
  components: {
    inputFormBox
  },
  data() {
    return {
      accountId: {
        isError: false,
        isOk: false,
        value: ''
      },
      isAvailable: null,
      isValid: null,
      errorReason: null
    }
  },
  props: {
    backAccountId: String
  },
  computed: {
    _checkAccountId() {
      return { isAvailable: this.isAvailable, isValid: this.isValid }
    },
    errorText() {
      if (this.errorReason === 'available') {
        return this.$i18n.t('signup.used.accountID')
      } else if (this.errorReason === 'tooLong') {
        return this.$i18n.t('signup.error.accountIdTooLong')
      } else {
        return this.$i18n.t('signup.error.accountID')
      }
    }
  },
  watch: {
    _checkAccountId({ isAvailable, isValid }) {
      if (!this.accountId.value) {
        this.accountId.isOk = null
        this.accountId.isError = null
        return
      }

      this.accountId.isOk = isAvailable && isValid

      if (this.accountId.isOk) {
        this.accountId.isError = false
      } else if (isAvailable === false) {
        this.accountId.isError = true
        this.errorReason = 'available'
      } else if (isValid === false) {
        this.accountId.isError = true
      }

      this.$emit('change', {
        value: this.accountId.value,
        isOk: this.accountId.isOk
      })
    }
  },
  methods: {
    _accountIdAvailable: debounce(async function () {
      const res = await axios.post(
        '/api/account_id_available',
        { accountId: this.accountId.value },
        { validateStatus: () => true }
      )
      this.isAvailable = res.data.status !== 'error'
    }, 400),
    _validateAccountId() {
      if (this.accountId.value === '') return

      if (this.accountId.value.length > 30) {
        this.isValid = false
        this.errorReason = 'tooLong'
        return
      }
      if (!this.accountId.value.match(/^[a-zA-Z0-9][a-zA-Z0-9_-]*$/g)) {
        this.isValid = false
        this.errorReason = 'default'
        return
      }

      this.isValid = true
    },
    changeVal(val) {
      this.accountId.isError = false
      this.accountId.isOk = false
      this.isAvailable = null
      this.isValid = null

      this.accountId.value = val
      this._validateAccountId()
      this._accountIdAvailable()
    }
  },
  mounted() {
    if (this.backAccountId) {
      this.accountId.value = this.backAccountId
    }
  }
}
</script>
