<template>
  <div class="login-Link">
    <button v-on:click="switchContent">
      <texts size="link" :text="$t('loginPage.resetPassword.loginLink')" />
    </button>
  </div>
</template>

<script>
import texts from '@/components/atoms/text.vue'

export default {
  components: {
    texts
  },
  data() {
    return {
      content: 'reset-password'
    }
  },
  methods: {
    switchContent() {
      this.content = 'login'
      this.$emit('switch-content', {
        content: this.content
      })
    }
  }
}
</script>
