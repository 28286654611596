<template>
  <div class="form-title">
    <div class="title">
      <texts size="big" :text="$t('loginPage.login.title')" />
    </div>
    <div class="signup-link">
      <signup-link />
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text.vue'
import signupLink from '@/components/molecules/login/signup-link.vue'

export default {
  components: {
    signupLink,
    texts
  }
}
</script>

<style lang="scss" scoped>
.form-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: $space-medium;
}
</style>
