<template>
  <div class="reset-password-form">
    <form @submit.prevent @keypress.prevent.enter.exact="submit">
      <div class="reset-password-item">
        <input-box-validation
          v-model="email"
          class="reset-password-inputbox"
          autocomplete="email"
          autocorrect="off"
          autocapitalize="off"
          :title="$t('loginPage.resetPassword.inputEmail')"
        />
      </div>
      <accept-message v-show="isAccepted" class="reset-password-item" />
      <error-message
        v-show="sendErrorMessage"
        :sendErrorMessage="sendErrorMessage"
        class="reset-password-item"
      />
      <button-large
        class="reset-password-submit-button"
        button-type="submit"
        type="emphasis"
        :text="$t('loginPage.resetPassword.submitButton')"
        :isDisabled="disableSubmit || isAccepted"
        @click="submit"
      />
    </form>
  </div>
</template>

<script>
import acceptMessage from '@/components/molecules/reset-password/accept-message.vue'
import errorMessage from '@/components/molecules/reset-password/error-message.vue'
import buttonLarge from '@/components/atoms/button-large.vue'
import inputBoxValidation from '@/components/molecules/input-box-validation.vue'

export default {
  components: {
    acceptMessage,
    errorMessage,
    buttonLarge,
    inputBoxValidation
  },
  data() {
    return { email: '', disableSubmit: true }
  },
  props: {
    isAccepted: {
      type: Boolean,
      default: false
    },
    isSubmitting: {
      type: Boolean,
      default: false
    },
    sendErrorMessage: {
      type: String,
      default: null
    }
  },
  computed: {
    _checkStatus() {
      return this.email && !this.isSubmitting
    }
  },
  watch: {
    _checkStatus(val) {
      if (val) {
        this.disableSubmit = false
      } else {
        this.disableSubmit = true
      }
    }
  },
  methods: {
    submit() {
      this.$emit('submit', {
        email: this.email
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.reset-password {
  width: 100%;
  background: $background;

  &-item {
    width: 100%;
    margin-bottom: $space-medium;
    font-size: $text-page-title;
    font-weight: bold;
    @include text-crop;
  }

  &-inputbox {
    width: 100%;
  }

  &-submit-button {
    width: 100%;
    margin-bottom: $space-small;
  }
}
</style>
